<template>
  <div class="column content-box">
    <section class="row">
        <div class="small-8 columns with-right-space ">
            <div class="sub_tabs">
                <div>
                    <ul class="tabs">
                        <li
                            v-for="tab in tabs"
                            class="tabs-title"
                            :class="tab.type === selectedTab ? 'is-active' : ''"
                            :key="tab.key"
                            @click="onSubTabChange(tab)"
                        >
                            <a>{{tab.name}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="small-4 columns with-left-space ">
            <div style="text-align:right;">
                <woot-button
                    v-if="selectedTab === 'sales'"
                    color-scheme="success"
                    class-names="button--fixed-right-top"
                    icon="arrow-download"
                    @click="downloadSaleReports"
                >
                Download sales report
                </woot-button>
                <woot-button
                    v-if="selectedTab === 'product'"
                    color-scheme="success"
                    class-names="button--fixed-right-top"
                    icon="arrow-download"
                    @click="downloadProductReports"
                >
                Download product report
                </woot-button>
                <date-range-picker
                    :confirm-text="$t('ORDER.LIST_TABLE.DATE_SELECT_CONFIRM_BTN')"
                    :placeholder="$t('ORDER.LIST_TABLE.DATE_SELECT_PLACEHOLDER')"
                    :value="dateRange"
                    @change="handleDateChange"
                    :disabledDate="disableFilterDate"
                />
            </div>
        </div>
    </section>

    <template>
        <SalesReport
            v-if="selectedTab === 'sales'"
            :isLoading="uiFlags.isFetching"
            :reports="salseReports"
            @on-sort-change="onSortChangeSales"
            :all_reports="salseReportsAll"
            :currency="currentUser.default_currency"
        />
        <ProductReport
            v-else-if="selectedTab === 'product'"
            :isLoading="uiFlags.isFetching"
            :reports="productReports"
            @on-sort-change="onSortChangeProduct"
            :all_reports="productReportsAll"
            :currency="currentUser.default_currency"
        />
        <div class="loadMore_btn">
          <woot-button
            v-if="selectedTab === 'sales'"
            variant="clear"
            size="small"
            color-scheme="primary"
            :is-loading="uiFlags.isFetching"
            :is-disabled="paginationCompute.isLastPage"
            @click="onPageChangeSales(Number(salesMeta.currentPage)+1)"
          >
            {{paginationCompute.btnText}}
          </woot-button>
          <woot-button
            v-else-if="selectedTab === 'product'"
            variant="clear"
            size="small"
            color-scheme="primary"
            :is-loading="uiFlags.isFetching"
            :is-disabled="paginationCompute.isLastPage"
            @click="onPageChangeProduct(Number(productMeta.currentPage)+1)"
          >
            {{paginationCompute.btnText}}
          </woot-button>
        </div>
    </template>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import ProductReport from "./ProductReport/ProductReport.vue"
import SalesReport from "./SalesReport/SalesReport.vue"
import DateRangePicker from "../../../../../dashboard/components/ui/DateRangePicker.vue"
import MerchantAPI from "../../../../api/merchant";

export default {
    components:{
        SalesReport,
        ProductReport,
        DateRangePicker,
    },
    data(){
        const endDate = new Date(); // Current date
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1); // One month ago
        return {
            merchant:{},
            tabs:[
                {key:0, name:'Sales',type:"sales",},
                {key:1, name:'Products',type:"product",},
            ],
            selectedTab: 'sales',
            dateRange: [startDate,endDate],
            sortInfoSales:{
                sortBy: 'date',
                sortOrder: 'desc'
            },
            sortInfoProducts:{
                sortBy: 'date',
                sortOrder: 'desc'
            },
        }
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            uiFlags: "orders/getUIFlags",
            salesMeta: "orders/getReportsSalesMeta",
            salseReportsAll: "orders/getSalesReports",
            salseReports: "orders/getPaginatedSalesReports",
            productMeta: "orders/getReportsProductMeta",
            productReportsAll: "orders/getProductReports",
            productReports: "orders/getPaginatedProductReports",

        }),
        paginationCompute(){
            if (this.selectedTab === 'product') {
                const totalPages = Math.ceil(this.productMeta.count / this.productMeta.pageSize);
                const isLastPage = this.productMeta.currentPage >= totalPages;
                const currentCount = Math.min(this.productMeta.currentPage * this.productMeta.pageSize, this.productMeta.count);
                const btnText = `Load More (${currentCount}/${this.productMeta.count})`;

                return{
                    isLastPage,
                    btnText,
                }
            }else{
                const totalPages = Math.ceil(this.salesMeta.count / this.salesMeta.pageSize);
                const isLastPage = this.salesMeta.currentPage >= totalPages;
                const currentCount = Math.min(this.salesMeta.currentPage * this.salesMeta.pageSize, this.salesMeta.count);
                const btnText = `Load More (${currentCount}/${this.salesMeta.count})`;

                return{
                    isLastPage,
                    btnText,
                }
            }
        },
    },
    mounted(){
        // this.$store.dispatch("orders/getSalesReports",{type:'sales',start_date:'2024-01-01',end_date:'2024-05-30'})
        this.handleDateChange(this.dateRange)
        // this.getReportByType(this.selectedTab)
        this.getMerchant()
    },
    methods:{
        onSubTabChange(tab){
            this.selectedTab = tab.type;
            this.handleDateChange(this.dateRange);
        },
        /*
        getReportByType(type,start_date,end_date){
            // this.$store.dispatch("orders/getReports",{type:'sales',start_date:'2024-01-01',end_date:'2024-05-21'})
            this.$store.dispatch("orders/getReports",{type,start_date,end_date})
        },
        */
        async handleDateChange(dateRange) {
            this.dateRange = dateRange

            const date = this.formatStartEndDate(dateRange[0],dateRange[1]);
            console.log({dateRange,date});
            if (this.selectedTab === 'product') {
                await this.$store.dispatch("orders/getProductReports",{
                    type:'product',
                    start_date: date.start_date,
                    end_date: date.end_date
                });
               this.onPageChangeProduct(1)

            }else{
                await this.$store.dispatch("orders/getSalesReports",{
                    type:'sales',
                    start_date: date.start_date,
                    end_date: date.end_date
                });
               this.onPageChangeSales(1)

            }

        },
        disableFilterDate(date,currentValues){
            const today = new Date();
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3); // 3 months ago

            if (date > today) {
                return true;
            }

            if (date < threeMonthsAgo) {
                return true;
            }
            return false;
        },
        formatStartEndDate(startDate,endDate){
            const providedStartDate = new Date(startDate);
            const providedEndDate = new Date(endDate);
            const option = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }
            const formattedStartDate = providedStartDate.toLocaleDateString('fr-CA', option);
            const formattedEndDate = providedEndDate.toLocaleDateString('fr-CA', option);

            return {
                start_date: formattedStartDate,
                end_date: formattedEndDate,
            };
        },
        onPageChangeSales(page,option={}) {
            // console.log("ck",{page,option});

            this.$store.dispatch("orders/getPaginatedSalesReports",{
                currentPage:page,
                pageSize:this.salesMeta.pageSize,
                sortBy: this.sortInfoSales.sortBy,
                sortOrder: this.sortInfoSales.sortOrder,
            })
        },
        onPageChangeProduct(page,option={}) {
            // console.log("ck",{page,option});

            this.$store.dispatch("orders/getPaginatedProductReports",{
                currentPage:page,
                pageSize:this.productMeta.pageSize,
                sortBy: this.sortInfoProducts.sortBy,
                sortOrder: this.sortInfoProducts.sortOrder,
            })
        },
        onSortChangeSales(params){
            let newSort = {
                sortBy: 'date',  // default sort
                sortOrder: 'desc'
            }
            Object.keys(params).forEach(key=>{
                if (params[key]) {
                newSort.sortBy = key;
                newSort.sortOrder = params[key];
                }
            })
            this.sortInfoSales = newSort;
            this.onPageChangeSales(1)
        },
        onSortChangeProduct(params){
            let newSort = {
                sortBy: 'date',  // default sort
                sortOrder: 'desc'
            }
            Object.keys(params).forEach(key=>{
                if (params[key]) {
                newSort.sortBy = key;
                newSort.sortOrder = params[key];
                }
            })
            this.sortInfoProducts = newSort;
            this.onPageChangeProduct(1)
        },
        downloadSaleReports(){
            console.log("sale report = ",this.salseReportsAll);
            const {start_date,end_date} = this.formatStartEndDate(this.dateRange[0],this.dateRange[1]);

            console.log({start_date,end_date});
            const fileName = `sales-report-${start_date}.csv`;
            console.log({fileName});
            // Filter out the required columns
            const filteredData = this.salseReportsAll.map(record => ({
                date: record.date,
                amount: record.amount
            }));

            // Convert to CSV
            const headers = ['date', 'amount'];
            const csvContent = [
                headers.join(','), // header row first
                ...filteredData.map(item => [item.date, item.amount].join(',')), // data rows
                "", // empty row
                `Reporting period ${start_date} to ${end_date}`
            ].join('\n');

            // Create a Blob from the CSV content
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            // Create a link to download the Blob
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        },
        downloadProductReports(){
            console.log("sale report = ",this.productReportsAll);
            const {start_date,end_date} = this.formatStartEndDate(this.dateRange[0],this.dateRange[1]);

            console.log({start_date,end_date});
            const fileName = `product-report-${start_date}.csv`;
            console.log({fileName});
            // Filter out the required columns
            const filteredData = this.productReportsAll.map(record => ({
                title: record.title,
                amount: record.amount
            }));

            // Convert to CSV
            const headers = ['title', 'amount'];
            const csvContent = [
                headers.join(','), // header row first
                ...filteredData.map(item => [item.title, item.amount].join(',')), // data rows
                "", // empty row
                `Reporting period ${start_date} to ${end_date}`
            ].join('\n');

            // Create a Blob from the CSV content
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            // Create a link to download the Blob
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        },
        async getMerchant(){
            try {
                const {data} = await MerchantAPI.getMerchantByName("");
                this.merchant = data;
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.FETCH_MERCHANT_FAIL'));
            }
        },
        // methods end
    },
}
</script>

<style scoped lang="scss">
.loadMore_btn button{
  display: block;
  margin: auto;
}
</style>
