/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-shadow: 0 */
import * as types from '../mutation-types';
import Report from '../../api/usageReport';
import FlowApi from '../../routes/dashboard/settings/bot/FlowApi';

const state = {
  isFetching: false,
  billingReport: null,
  quotaMessaging: null,
  botsReport: null,
};

const getters = {
  getBotsReport(_state) {
    return _state.botsReport;
  },
  getBillingReports(_state) {
    return _state.billingReport;
  },
  getQuotaMessaging(_state) {
    return _state.quotaMessaging;
  },
  getIsFetchingUsageReport(_state) {
    return _state.isFetching;
  },
};
export const actions = {
  botsTransaction({ commit }, args) {
    console.log('args', args);
    commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, true);
    if (args.account_id) {
      FlowApi.getBotsUsage(0, true, args.account_id, args.from, args.to)
        .then(apiResponse => {
          // console.log('bot usage api res', apiResponse, apiResponse.data);
          commit(types.default.SET_BOTS_REPORTS, apiResponse.data);
        })
        .catch(() => {
          commit(types.default.SET_BOTS_REPORTS, null);
        })
        .finally(() => {
          commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
        });
    } else {
      commit(types.default.SET_BOTS_REPORTS, null);
      commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
    }
  },
  paidMessaging({ commit }, reportObj) {
    commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, true);
    Report.getReports(reportObj.apiUrl, reportObj.from, reportObj.to)
      .then(billingReport => {
        let { data } = billingReport;
        commit(types.default.SET_USAGE_REPORTS, data);
      })
      .catch(error => {
        console.error(error);
        commit(types.default.SET_USAGE_REPORTS, null);
      })
      .finally(() => {
        commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
      });
  },
  quotaMessaging({ commit }, reportObj) {
    commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, true);
    Report.getReports(reportObj.apiUrl, reportObj.from, reportObj.to)
      .then(accountReport => {
        let { data } = accountReport;
        commit(types.default.SET_QUOTA_REPORTS, data);
      })
      .catch(error => {
        console.error(error);
        commit(types.default.SET_QUOTA_REPORTS, null);
      })
      .finally(() => {
        commit(types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING, false);
      });
  },
};

const mutations = {
  [types.default.SET_USAGE_REPORTS](_state, billingReport) {
    _state.billingReport = billingReport;
  },
  [types.default.SET_BOTS_REPORTS](_state, botsReport) {
    _state.botsReport = botsReport;
  },
  [types.default.SET_QUOTA_REPORTS](_state, quotaMessaging) {
    _state.quotaMessaging = quotaMessaging;
  },
  [types.default.TOGGLE_USAGE_BILLING_REPORT_LOADING](_state, flag) {
    _state.isFetching = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
