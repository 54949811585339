<template>
  <div  :style="{ height: index === 0 ? '0' : 'auto', overflow: index === 0 ? 'hidden' : 'visible' }">
    <div>
        <multiselect
            v-model="business_hour.day"
            :options="getDayOptions()"
            class="small-12"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            placeholder="Pick Order Status"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            :key="'day_' + index"
        />
    </div>
                            
    <div>
        <multiselect
            v-model="business_hour.open"
            :options="[...TIME_INTERVAL]"
            class="small-12"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            placeholder="Pick Order Status"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            :key="'open_' + index"
        />
    </div>
    <div class="margin_bottom" style="text-align: center;"><p>To</p></div>
    <div>
        <multiselect
            v-model="business_hour.close"
            :options="[...TIME_INTERVAL]"
            class="small-12"
            track-by="id"
            label="title"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :hide-selected="true"
            placeholder="Pick Order Status"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            :key="'close_' + index"
        />
    </div> 
    

    <div style="min-height:40px;">
        <woot-button
            variant="smooth"
            size="small"
            color-scheme="alert"
            icon="delete"
            @click="removeBusinessHourHandler(index)"
        >
        </woot-button>
    </div>
    </div> 
</template>

<script>
export default {
    props:{
        business_hour:{
            type: Object,
            default:{
                day: {},
                open: {},
                close: {},
            }
        },
        TIME_INTERVAL:{
            type: Array,
            default: []
        },
        DAY_NAMES:{ 
            type: Array,
            default: []
        },
        index:{
            type: Number,
            default: 0
        },
        removeBusinessHour:{
            type: Function,
            default: (index)=>{}
        },
        
    },
    computed: {
        getDayOptions() {
            return function(index) {
                return [...this.DAY_NAMES];
            };
        },
        getTimeOptions() {
            return function(index) {
                return [...this.TIME_INTERVAL];
            };
        }
    },
    methods:{
        removeBusinessHourHandler(index){
            console.log({index});
            this.removeBusinessHour(index)
        },
    },
}
</script>

<style>

</style>