<template>
  <div class="parent-container">
    <nav class="navbar navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img
            src="~dashboard/assets/images/menu-icons/header-logo.svg"
            alt="header-logo"
            height="60"
          />
        </a>
        <div class="navbar-right-content">
          <notification-bell />
          <agent-details @toggle-menu="toggleOptions" />
          <options-menu
            :show="showOptionsMenu"
            @toggle-accounts="toggleAccountModal"
            @show-support-chat-window="toggleSupportChatWindow"
            @key-shortcut-modal="toggleKeyShortcutModal"
            @close="toggleOptions"
          />
          <img
            role="button"
            src="~dashboard/assets/images/menu-icons/arrow-down.svg"
            class="dropdown-arrow"
            alt="dropdown-arrow"
            @click="toggleOptions"
          />
        </div>
      </div>
    </nav>
    <div class="row app-wrapper">
      <sidebar
        ref="sidebar"
        :route="currentRoute"
        :class="sidebarClassName"
      ></sidebar>
      <section class="app-content columns" :class="contentClassName">
        <router-view></router-view>
        <command-bar />
      </section>
      <!-- Instruction modal Open to acess all pages  -->
      <DemoInstructionPopup />
    </div>
  </div>
</template>

<script>
import Sidebar from '../../components/layout/Sidebar';
import CommandBar from './commands/commandbar.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import AgentDetails from '../../components/layout/sidebarComponents/AgentDetails.vue';
import OptionsMenu from '../../components/layout/sidebarComponents/OptionsMenu';
import NotificationBell from '../../components/layout/sidebarComponents/NotificationBell.vue';
import Cookies from 'js-cookie';
import DemoInstructionPopup from '../../components/layout/sidebarComponents/DemoInstructionPopup.vue';

export default {
  components: {
    Sidebar,
    CommandBar,
    AgentDetails,
    OptionsMenu,
    NotificationBell,
    DemoInstructionPopup,
  },
  data() {
    return {
      isSidebarOpen: false,
      isOnDesktop: true,
      showOptionsMenu: false,
    };
  },
  computed: {
    currentRoute() {
      return ' ';
    },
    sidebarClassName() {
      if (this.isOnDesktop) {
        return '';
      }
      if (this.isSidebarOpen) {
        return 'off-canvas position-left is-transition-push is-open';
      }
      return 'off-canvas is-transition-push is-closed';
    },
    contentClassName() {
      if (this.isOnDesktop) {
        return '';
      }
      if (this.isSidebarOpen) {
        return 'off-canvas-content is-open-left has-transition-push has-position-left';
      }
      return 'off-canvas-content has-transition-push';
    },
  },
  mounted() {
    this.$store.dispatch('setCurrentAccountId', this.$route.params.accountId);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    bus.$on(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);

    try {
      const user = Cookies.getJSON('user') || {};

      console.log('Updating DataLayer For ', user);

      let userData = user || null;

      if (userData) {
        window.dataLayer.push({
          user_email: userData.email || '',
          user_name: userData.name || '',
          ps_plan: userData.plan_id || 0,
          ps_account_id: userData.account_id || '',
          ps_stripe_user_id: userData.stripe_customer_id || '',
          ps_currency: userData.default_currency || '',
          ps_balance: userData.account_top_amount || 0,
          ps_total_topup: userData.total_top_amount || 0,
          onboard_limit: userData.onboard_limit || 0,
          role: userData.role || '',
        });
        console.log('dataLayer Updated', window.dataLayer);
      }
    } catch (er) {
      console.log('Tag Error ', er);
    }
  },
  beforeDestroy() {
    bus.$off(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 1200) {
        this.isOnDesktop = true;
      } else {
        this.isOnDesktop = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleSupportChatWindow() {
      window.$peasy.toggle();
    },
    toggleAccountModal() {
      this.$refs.sidebar.toggleAccountModal();
    },
    toggleKeyShortcutModal() {
      this.$refs.sidebar.toggleKeyShortcutModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.parent-container {
  // height: calc(100vh - 60px);
  height: 100%;
}

.off-canvas-content.is-open-left {
  transform: translateX(25.4rem);
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 60px;
  .container {
    width: 100%;
    padding-right: 3.171rem;
    margin-right: auto;
    margin-left: auto;

    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
  }
}

.navbar-brand {
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  img {
    height: 60px;
  }
}

.navbar-right-content {
  display: flex;
}

.dropdown-arrow {
  padding-left: 12px;
  cursor: pointer;
}
</style>
