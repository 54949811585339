<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
        <woot-modal-header
        :header-title="$t('ORDER.DETAILS.ADD_ITEM_MODAL.HEADER.TITLE')"
        :header-content="$t('ORDER.DETAILS.ADD_ITEM_MODAL.HEADER.CONTENT')"
      />
    </div>
    <section class="modal_body">
        <div>
            <label>
                <span>{{$t('ORDER.DETAILS.ADD_ITEM_MODAL.SEARCH_LABEL')}}</span>
                <input
                    v-model="searchText"
                    type="search"
                    placeholder="eg, Biscuit"
                    @keyup.enter="searchVariantHandler"
                />
            </label>
        </div>
        <div>
            <label>
                <span>{{$t('ORDER.DETAILS.ADD_ITEM_MODAL.CATEGORY_LABEL')}}</span>
            </label>
            <multiselect
                v-model="selectedCategories"
                :options="categories"
                track-by="id"
                label="title"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :hide-selected="true"
                :tag-placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES')"
                :taggable="false"
                :placeholder="$t('INBOX_MGMT.SETTINGS_POPUP.CATALOGUES.CATEGORY_SELECT_PLACEHOLDER')"
                selected-label
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                @select="addFilterByCategories($event,option)"
                @remove="removeFilterByCategories($event)"
            />
        </div>

        <div v-if="!isLoading" class="cards">
            <div 
                v-for="item in filteredVariants" 
                @click="onClickCard(item)" 
                :key="item.id"
                class="card"
                :class="{'active_card':activeCardClass(item)}"
            >
                <div>
                    <div>
                        <img class="card_img" :src="item.image_link" alt="">
                    </div>
                    <div class="card_info">
                        <p><b>{{item.item_name}}</b></p>
                        <p>Price: {{item.price}} {{item.currency}}</p>
                        
                    </div>
                </div>
            </div>
        </div>

        <!-- loading and notfound -->
        <div>
            <div v-if="isVariantLoading" class="loader_and_empty">
                <spinner />
                <span>Loading items</span>
            </div>
            <empty-state
                v-else-if="!isVariantLoading && !filteredVariants.length"
                title="No item found!"
            />
        </div>

        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              button-text="Add items"
              @click="addItemsHandler"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
        
    </section>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import Button from '../../../../components/buttons/Button.vue';
import OrderAPI from  '../../../../api/orders';
import {methodTils} from "../../../../helper/methodTils";
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';

export default {
    components: {
        WootSubmitButton,
        Modal,
        VeTable,
        Spinner,
        EmptyState,
    },
    mixins: [alertMixin],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        onClose: {
            type: Function,
            default: () => {},
        },
        order: {
            type: Object,
            default: () => {},
        },
        categories:{
            type: Array,
            default: []
        },
        catalogueItems:{
            type: Array,
            default: []
        },
    },
    data(){
        return {
            selectedCategories:[],
            filteredVariants:[],
            selectedRowIds:[],
            isVariantLoading:false,
            searchText:"",
        }
    },
    computed:{
        // end computed
    },
    methods:{
        async addItemsHandler(){
            try {
                console.log({selectedRowIds:this.selectedRowIds});
                const newItems = this.selectedRowIds.filter(id => !this.order.order_items.some(item => item.id === id));
                this.order.order_items.push(...newItems);
                this.showAlert(this.$t('ORDER.DETAILS.ADD_ITEM_MODAL.ADD_SUCCESS_MSG'));
                this.onClose();
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('ORDER.DETAILS.ADD_ITEM_MODAL.ADD_FAIL_MSG'));
            }
        },
        
        async filterVariantsByCategories(searchText,categoriesToFilter) {
            console.log({searchText,categoriesToFilter});
            this.isVariantLoading = true;
            let allVariants = [];

            // firt make a clean array record
            // apply filter on categoriesToFilter, if categoriesToFilter empty, skip
            // apply filter on searchText, if searchtext empty, skip
            // validate images
            // set the data

            const hasCtgLength = categoriesToFilter.length;
            console.log({hasCtgLength});
            this.catalogueItems.forEach(item => {
                // check categories(if categoriesToFilter.length is 0, take all)

                if (!hasCtgLength || item.categories.some(category => categoriesToFilter.some(filterCtg => filterCtg.id === category.id))) {
                    const currency= item.price?.split(" ")[1]
                    const mainVariant = {
                        id: item.id,
                        title: item.title,
                        item_name: item.title,
                        get item_name(){
                            return this.title;
                        },
                        image_link: item.image_link,
                        price: parseFloat(item.price?.split(" ")[0] || 0),
                        get item_price(){
                            return this.price;
                        },
                        currency,
                        catalog_item_id: item.id,
                    };
                    console.log({mainVariant});
                    const variants = [mainVariant, ...item.variants].map(variant => ({ 
                        ...variant,
                        item_name: variant.item_name ? variant.item_name : `${mainVariant.title}-${variant.title}`,
                        catalog_item_id: item.id,
                        price:parseFloat(variant.price), 
                        item_price:parseFloat(variant.price), 
                        currency, 
                        quantity: 0 
                    }));
                    allVariants.push(...variants);
                }
            });
            
            // filter by 
            if (searchText) {
                allVariants = allVariants.filter(variant=>variant.item_name?.toLowerCase()?.includes(searchText.trim()?.toLowerCase()))
            }

            // get valid image url
            const validationPromises = [];
            allVariants.forEach(variant => {
                validationPromises.push(methodTils.getValidImageUrl(variant.image_link || ""));
            });
            const validatedImageUrls = await Promise.all(validationPromises);
            allVariants.forEach((variant, index) => {
                variant.image_link = validatedImageUrls[index];
            });

            this.filteredVariants = allVariants;
            this.isVariantLoading = false;
            console.log({allVariants});
        },
        
        addFilterByCategories(addedItem,option){
            console.log(1234);
            console.log({addedItem,option});
            const newCtgs = [...this.selectedCategories,addedItem];
            this.filterVariantsByCategories(this.searchText,newCtgs)
            console.log({newCtgs});
        },
        removeFilterByCategories(removedItem){
            const newCtgs = this.selectedCategories.filter(el=>el.id !== removedItem.id);
            console.log({newCtgs});
            this.filterVariantsByCategories(this.searchText,newCtgs)
        },
        onClickCard(item){
            const isExist = this.selectedRowIds.find(el=>el.id === item.id)
            if (isExist) {
                this.selectedRowIds = this.selectedRowIds.filter(el=>el.id !== item.id)
            }else{
                this.selectedRowIds.push(item)
            }
        },
        activeCardClass(item){
            const exist = this.selectedRowIds.find(el=>el.id === item.id)
            return exist ? true:false;
        },
        searchVariantHandler(e){
            console.log({inp:e?.target?.value,e});
           this.filterVariantsByCategories(e.target.value,this.selectedCategories);
        },

        // end methods
    },
}
</script>


<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.modal_body{
    min-height: 300px;
    padding: 3.2rem 3.2rem;
}
.cards{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
}
.card{
    position: relative;
    padding: 0;

    .card_img{
        object-fit: cover;
        height: 80px;
        width: 100%;
        border-top-left-radius: $space-small;
        border-top-right-radius: $space-small;
    }
    .card_info{
      padding: $space-small;
    }
}
.active_card{
    border: 1px solid #6F1DDF;
}
.loader_and_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-default);
  padding: var(--space-big);
}
</style>