<template>
  <div class="row">
    <div class="column content-box">
      <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>
      <woot-button
        color-scheme="success"
        class-names="button--fixed-right-top"
        icon="add-circle"
        @click="openTopUpModal"
      >
        {{ $t('TOP_UP.BUTTON_TITLE') }}
      </woot-button>
      <div class="columns">
        <stripe-component
          :modal-value="this.topUpModal"
          @close-modal="closeModal"
        ></stripe-component>
        <paid-messaging v-if="selectedTabIndex == 0"></paid-messaging>
        <bots-transactions v-if="selectedTabIndex == 1"></bots-transactions>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import PaidMessaging from './PaidMessaging';
// import QuotaMessaging from './QuotaMessaging';
import StripeComponent from 'dashboard/components/stripe/StripeComponent';
import stripeMixin from '../../../../../../shared/mixins/stripeMixin';
import BotsTransactions from './BotsTransactions';

export default {
  components: {
    PaidMessaging,
    StripeComponent,
    BotsTransactions,
  },
  mixins: [alertMixin, stripeMixin],
  data() {
    return {
      selectedTabIndex: 0,
      topUpModal: false,
    };
  },
  computed: {
    ...mapGetters({
      isFetching: 'getIsFetchingUsageReport',
      currentUserId: 'getCurrentUserID',
      currentUser: 'getCurrentUser',
    }),
    tabs() {
      return [
        {
          key: 0,
          name: this.$t('BILLING_USAGES.TAB_PAID_MESSAGING'),
        },
        {
          key: 1,
          name: this.$t('BILLING_USAGES.TAB_BOT_TRANSACTIONS'),
        },
        // {
        //   key: 1,
        //   name: this.$t('BILLING_USAGES.TAB_QUOTA_MESSAGING'),
        // },
      ];
    },
  },
  mounted() {
    this.checkStripeSuccess();
  },
  methods: {
    onClickTabChange(index) {
      this.selectedTabIndex = index;
    },
    openTopUpModal() {
      this.topUpModal = !this.topUpModal;
    },
    closeModal() {
      this.topUpModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  overflow: unset;
}
.table-wrap {
  padding-left: var(--space-small);
}

.woot-table {
  width: 100%;
  margin-top: var(--space-small);
}

.no-items-error-message {
  margin-top: var(--space-larger);
}

.tabs {
  padding-left: 0;
  margin-right: var(--space-medium);
  user-select: none;
}

.item {
  padding-left: 0;
  max-width: 10rem;
  min-width: 8rem;
}

.item-description {
  padding-left: 0;
  max-width: 16rem;
  min-width: 10rem;
}

.key {
  font-family: monospace;
}

::v-deep {
  .tabs-title a {
    font-weight: var(--font-weight-medium);
    padding-top: 0;
  }
}
</style>
