<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CATALOGUE.ADD_EDIT_FORM.HEADER.TITLE')"
        :header-content="$t('CATALOGUE.ADD_EDIT_FORM.HEADER.CONTENT')"
      />
      <form ref="form" class="row" @submit.prevent="addCatalogueItem()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.title.$error }">
            {{ $t('CATALOGUE.ADD_EDIT_FORM.TITLE_LABEL') }}
            <input
              v-model.trim="title"
              type="text"
              :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.TITLE_PLACEHOLDER')"
              @input="$v.title.$touch"
            />
          </label>
        </div>
        <!-- make description field   -->
        <div class="medium-12 columns">
          <label :class="{ error: $v.description.$error }">
            {{ $t('CATALOGUE.ADD_EDIT_FORM.DESCRIPTION_LABEL') }}
            <textarea
              v-model.trim="description"
              rows="5"
              type="text"
              :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.DESCRIPTION_PLACEHOLDER')"
              @input="$v.description.$touch"
            />
          </label>
        </div>
        <!-- make file field   -->
        <div class="medium-12 columns display-inline">
          <label>
            <span>{{ $t('CATALOGUE.ADD_EDIT_FORM.IMG_LABEL') }}</span>
            <input
              id="file"
              ref="file"
              type="file"
              accept="image/*,"
              @change="handleImageUpload"
            />
          </label>
        </div>
        <div class="medium-12 columns display-inline">
            <div class="medium-6 columns">
                <label :class="{ error: $v.price.$error }">
                    <span>{{ $t('CATALOGUE.ADD_EDIT_FORM.PRICE_LABEL') }}</span>
                    <input
                        v-model.trim="price"
                        type="number"
                        step="any"
                        :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.PRICE_PLACEHOLDER')"
                        @input="$v.price.$touch"
                    />
                </label>
            </div>
            <div class="medium-6 columns">
                <label>
                    <span>{{ $t('CATALOGUE.ADD_EDIT_FORM.CURRENCY_LABEL') }}</span>
                    <select v-model="currency">
                        <option value="MYR">MYR</option>
                        <option value="USD">USD</option>
                    </select>
                </label>
            </div>
        </div>
        <div class="medium-12 columns display-inline">
          <div class="medium-6 columns">
            <label>
                <span>{{ $t('CATALOGUE.ADD_EDIT_FORM.AVAILABILITY_LABEL') }}</span>
                <select v-model="availability">
                    <option value="in stock">In Stock</option>
                    <option value="out stock">Out Stock</option>
                </select>
            </label>
          </div>
          <div class="medium-6 columns">
            <label :class="{ error: $v.inventory.$error }">
                <span>Inventory</span>
                <input
                  v-model.trim="inventory"
                  type="number"
                  step="any"
                  placeholder="eg, 1, 2, 3....."
                  @input="$v.inventory.$touch"
                />
            </label>
          </div>
        </div>
        <!-- categories field make  -->
        <div class="medium-12 columns">
          <label>
            <span>{{ $t('CATALOGUE.ADD_EDIT_FORM.ASSIGN_CTG_LABEL') }}</span>
            <multiselect
                v-model="selectedCategories"
                :options="categories"
                class="small-12"
                track-by="id"
                label="title"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :hide-selected="true"
                :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.ASSIGN_CTG_PLACEHOLDER')"
                selected-label
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            />
          </label>
        </div>


        <div class="medium-12 columns">
          <div class="addVariant_label">
            <label><span>{{ $t('CATALOGUE.ADD_EDIT_FORM.VARIANT_LABEL') }}</span></label>
            <div>
                <div>
                    <woot-button
                      color-scheme="primary"
                      variant="smooth"
                      size="small"
                      icon="add"
                      @click="addVariant"
                    />
                </div>
            </div>
          </div>
            <div v-for="(variant, index) in variants" class="variant_inputs" :key="index">
                <div class="medium-5 columns" :class="{ error: isFieldError('title', index) }">
                    <input
                        v-model.trim="variant.title"
                        type="text"
                        :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.VARIANT_TITLE_PLACEHOLDER')"
                    />
                    <p v-if="isFieldError('title',index)" class="error_message">
                      {{$t('CATALOGUE.ADD_EDIT_FORM.VARIANT_TITLE_REQUIRED_ERR')}}
                    </p>
                </div>
                <div class="medium-5 columns" :class="{ error: isFieldError('price', index) }">
                    <input
                        v-model.trim="variant.price"
                        type="number"
                        step="any"
                        :placeholder="$t('CATALOGUE.ADD_EDIT_FORM.VARIANT_PRICE_PLACEHOLDER')"
                    />
                    <p v-if="isFieldError('price',index)" class="error_message">
                      {{$t('CATALOGUE.ADD_EDIT_FORM.VARIANT_PRICE_REQUIRED_ERR')}}
                    </p>
                </div>
                <div class="medium-2 columns">
                    <div>
                        <button
                            @click="removeVariant(index)"
                            type="button"
                            style="margin-top: 10px;"
                        >
                            <fluent-icon icon="dismiss" />
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div>
                <div>
                    <button class="add_variant_btn" @click="addVariant" type="button">
                        <fluent-icon icon="add" />
                    </button>
                </div>
            </div> -->
        </div>



        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :button-text="$t('CATALOGUE.ADD_EDIT_FORM.ADD_BTN')"
              :loading="uiFlags.isCreating_catalog_items"
              :disabled="uiFlags.isCreating_catalog_items"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import methodHelperMixin from '../../../../mixins/methodHelperMixin';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  mixins: [alertMixin,methodHelperMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
        show: true,
        title:"",
        description:"",
        price:"",
        currency:"MYR",
        availability:"in stock",
        inventory:"",
        file: "",
        variants: [
          // { title: '', price: '' }
        ],
        selectedCategories:[],   // selected Categories
    };
  },
  validations: {
    title: {
      required,
    },
    description: {
      required,
    },
    price: {
      required,
    },
    inventory: {
      required,
    },
    variants:{
        $each:{
            title:{
                required
            },
            price:{
                required
            },
        }
    },
    availability: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      categories: "catalog/getCatagories",
      uiFlags: "catalog/getUIFlags",
    }),
  },
  methods: {

    resetForm() {
      this.$refs.form.reset();
    },
    async addCatalogueItem() {
        const payload = {
            title: this.title,
            description: this.description,
            price:`${this.price} ${this.currency}`,
            availability: this.availability,
            inventory: this.inventory,
            categories: this.selectedCategories.map(el=>el.id),
            file: this.file,
            variants: this.variants
        }

        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }

        // Create a new FormData object
        const formData = new FormData();
        formData.append('title', payload.title);
        formData.append('description', payload.description);
        formData.append('price', payload.price);
        formData.append('availability', payload.availability);
        formData.append('inventory', payload.inventory);
        formData.append('categories', JSON.stringify(payload.categories));
        formData.append('file', payload.file);
        formData.append('variants', JSON.stringify(payload.variants));


        try {
            const ctgResult = await this.$store.dispatch("catalog/createCatalogueItem",formData)
           await this.$store.dispatch("catalog/getCatalogProductItems");

            this.showAlert(this.$t('CATALOGUE.CRUD.CATALOGUE.CREATE_SUCCESS'));
            this.resetForm();
            this.onClose();

        } catch (error) {
            console.log(error);
            this.showAlert(this.$t('CATALOGUE.CRUD.CATALOGUE.CREATE_FAIL'));
        }
    },
    async handleImageUpload(event) {
      this.file = event.target.files[0];

      if (this.file.size > 1024 * 1024 * 40) {
        alert('File is too big. Please select file of size less then 40mb.');
        this.$refs.file.value = null;
        this.file = '';
      }
      // compress and resize the image
      if (event.target.files[0]) {
        const resizedImage = await this.resizeImage(event.target.files[0], { maxWidth: 500, maxHeight: 500 });
        // console.log({resizedImage,original:event.target.files[0]});
        const compressedImage = await this.compressImage(resizedImage, { maxSizeKB: 800, initialQuality: 0.9, qualityStep: 0.1 });
        this.file = compressedImage
      }

    },
    addVariant() {
        this.variants.push({ title: '', price: '' });
    },
    removeVariant(index) {
        this.variants.splice(index, 1);
    },
    isFieldError(fieldName, index) {
        const result = this.$v.variants.$each[index]?.[fieldName]?.$error || false;
        return result
    },

  },
};
</script>
<style scoped lang="scss">
.display-inline {
  display: inline-flex;
  justify-content: space-between;
}

.variant_inputs{
    display: flex;
    gap: 0.5rem;

    input{
        margin: 0;
    }
}
.addVariant_label{
  display: flex;
  gap: 1rem;
  margin-bottom: 10px;
  align-items: center;
}
.error_message{
    color: red;
}
</style>
