<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
        <woot-modal-header
        :header-title="$t('CATALOGUE.SETTING.LOCATION_MAP.MODAL_HEADER')"
        :header-content="$t('CATALOGUE.SETTING.LOCATION_MAP.MODAL_CONTENT')"
      />
    </div>
    <div class="map_container">
      <section class="place_container">
        <div>
          <input
            type="search"
            placeholder="Search location"
            v-model="searchQuery"
          >
        </div>
        <div>
          <ul class="places">
            <li
              v-for="place in places"
              class="place"
              :key="place.place_id"
              @click="selectPlace(place)"
            >
              <div class="icon_wrap">
                <img class="icon" :src="place.icon" alt="">
              </div>
              <div>
                <h6>{{place.name}}</h6>
                <p>{{place.formatted_address}}</p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <div id="map" style="height: 400px;"></div>
      </section>

      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-submit-button
            :button-text="$t('CATALOGUE.SETTING.LOCATION_MAP.ADD_BTN')"
            @click="addLocationHandler"
          />
          <button class="button clear" @click.prevent="onClose">
            {{ $t('TEMPLATE_MSG.ADD.CANCEL_BUTTON_TEXT') }}
          </button>
        </div>
      </div>
    </div>

  </modal>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import { ExternalConfig } from "../../../../dashboard/settings/inbox/channels/constant";
import Modal from '../../../../../components/Modal.vue';

export default {
  name: "GoogleMap",
  components:{
    Modal,
  },
  data() {
    return {
      map: null,
      geocoder: null,
      defaultPosition: {
        lat: 0.00,
        lng: 0.00
      },
      searchQuery: '',
      places:[],
      marker: null,
    };
  },
  props:{
    show: {
        type: Boolean,
        default: false,
    },
    onClose: {
        type: Function,
        default: () => {},
    },
    address:{
      type: Object,
      default:{}
    },
  },
  mounted() {
    this.initMap();
  },
  beforeDestroy(){
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }
  },
  watch:{
    searchQuery:{
      handler(newQuery){
        this.debounceSearch(newQuery)
      },
    },
    // end watch
  },
  methods: {
    addLocationHandler(){
      this.onClose();
    },
    initMap() {
      const loader = new Loader({
        apiKey: ExternalConfig.GOOGLE_MAP_API_KEY,
        version: 'weekly',
        libraries: ["places"],
      });

      loader.load().then(async() => {
        // Initialize Geocoder
        this.geocoder = new google.maps.Geocoder();

        // set current location
        await this.setCurrentLocation();

        // Create map
        this.map = new google.maps.Map(document.getElementById("map"), {
          zoom: 16,
          center: this.defaultPosition,
        });

        // Create marker
        this.marker = new google.maps.Marker({
          position: this.defaultPosition,
          map: this.map,
          draggable: true,
        });

        // Add event listener for marker dragend
        this.marker.addListener('dragend', () => {
          this.getMarkerDetails(this.marker.getPosition());
        });
      }).catch((error) => {
        console.error("Failed to load Google Maps API:", error);
      });
    },
   getMarkerDetails(position) {
      this.geocoder.geocode({ location: position }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            console.log("AD00 = ",results[0]);
            const address = this.parseAddressComponents(results[0].address_components);
            const formattedAddress = {
              lat: position.lat(),
              lng: position.lng(),
              address: {
                buildingName: address.buildingName || "",
                road: address.road || "",
                city: address.city || "",
                state: address.state || "",
                country: address.country || "",
                post: address.post || "",
                postalCode: address.postalCode || "",
              }
            };
            // console.log("Location details:", formattedAddress.address);
            const {lat,lng,address:{buildingName,city,country,post,postalCode,road,state}} = formattedAddress;
            // set data to props
            this.address.latitude = lat;
            this.address.longitude = lng;
            this.address.postcode = `${post} ${postalCode}`|| "";
            this.address.state = state|| "";
            this.address.city = city||"";
            const newLine1 = `${buildingName || ""}${buildingName && road ? ", " : ""}${road || ""}`;
            this.address.line1 = newLine1.trim() || "";
          } else {
            console.error("No results found");
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    },
    parseAddressComponents(addressComponents) {
        const parsedAddress = {};
        addressComponents.forEach(component => {
          const types = component.types;
          if (types.includes("street_number")) {
            parsedAddress.streetNumber = component.long_name;
          } else if (types.includes("route")) {
            parsedAddress.road = component.long_name;
          } else if (types.includes("sublocality") || types.includes("locality")) {
            parsedAddress.city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            parsedAddress.state = component.long_name;
          } else if (types.includes("country")) {
            parsedAddress.country = component.long_name;
          } else if (types.includes("postal_code")) {
            parsedAddress.postalCode = component.long_name;
          } else if (types.includes("premise")) {
            parsedAddress.buildingName = component.long_name;
          }
        });
        return parsedAddress;
    },

    async setCurrentLocation(){
      if (navigator.geolocation) {
        try {
          const position = await new Promise((resolve,reject)=>{
            navigator.geolocation.getCurrentPosition(resolve,reject)
          })
          this.defaultPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          // Create a LatLng object
          const googleMapPosition = new google.maps.LatLng(this.defaultPosition.lat, this.defaultPosition.lng);

          // Update address props based on current location
          this.getMarkerDetails(googleMapPosition);

          // Set map center to current location
          this.map?.setCenter(googleMapPosition);

        } catch (error) {
          console.log(error);
        }
      }
    },
    /*
    searchLocation() {
      const service = new google.maps.places.PlacesService(this.map);
      service.textSearch({ query: this.searchQuery }, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          console.log({results});
          if (results[0]) {
            const position = results[0].geometry.location;
            this.map.setCenter(position);
            this.map.setZoom(16);
            const marker = new google.maps.Marker({
              position: position,
              map: this.map,
              draggable: true,
            });
            marker.addListener('dragend', () => {
              this.getMarkerDetails(marker.getPosition());
            });
            this.getMarkerDetails(position);
          } else {
            console.error("No results found");
          }
        } else {
          console.error("Search failed due to: " + status);
        }
      });
    },
    */
    placeAutoComplete() {
      const service = new google.maps.places.PlacesService(this.map);
      service.textSearch({ query: this.searchQuery }, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          // console.log({results});
          this.places = results;
        }
      })


    },
    debounceSearch(queryText){
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(()=>{
        this.placeAutoComplete()
      },300)
    },
    selectPlace(place){
      const position = place.geometry.location;
      this.map.setCenter(position);
      this.map.setZoom(16);
      const marker = new google.maps.Marker({
        position: position,
        map: this.map,
        draggable: true,
      });
      marker.addListener('dragend', () => {
        this.getMarkerDetails(marker.getPosition());
      });
      this.getMarkerDetails(position);
      // make clear the existing palces
      this.places = []
    },






  },
};
</script>

<style lang="scss" scoped>
.map_container{
  padding: 0 3.2rem 3.2rem 3.2rem;
}
.place_container{
  position: relative;

  .places{
    position: absolute;
    width: 400px;
    background: #fff;
    top: 42px;
    left: 10px;
    z-index: 1;
    list-style: none;
    margin: 0;
    box-shadow: 0 0 5px 1px lightgray;
    max-height: 300px;
    overflow-y: auto;

    .place{
      display: grid;
      grid-template-columns: 50px 1fr;
      gap: 1rem;
      align-items: center;
      padding: 2px 8px;
      transition: all 0.2s ease-in-out;
      padding: 10px 0;

      .icon_wrap{
        text-align: center;
        .icon{
          width: 28px;
        }
      }
      h6{
        font-size: 1.4rem;
      }
      &:hover{
        background-color: var(--color-background-light);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

</style>