<template>
  <section class="columns small-12">
    <aside class="margin_section">
      <div class="report-bar">
        <woot-loading-state
          v-if="isFetching"
          :message="$t('REPORT.LOADING_CHART')"
        />
        <div v-else class="chart-container">
          <woot-bar
            v-if="reports.length"
            :collection="collection"
          />
          <span v-else class="empty-state">
            {{ $t('REPORT.NO_ENOUGH_DATA') }}
          </span>
        </div>
      </div>

    </aside>
    <aside class="margin_section">
      <div class="orders-table-wrap">
        <ve-table
          :fixed-header="true"
          max-height="100%"
          style="width:100%"
          :columns="columns"
          :table-data="tableData"
          :border-around="false"
          :sort-option="sortOption"
        />
        <empty-state
          v-if="!isLoading && !reports.length"
          :title="'No Report Available'"
        />
        <div v-if="isLoading" class="orders--loader">
          <spinner />
          <span>Loading product reports</span>
        </div>
      </div>
    </aside>

  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';

export default {
  components:{
    VeTable,
    Spinner,
  },
  props:{
    reports:{
      type: Array,
      default: []
    },
    all_reports:{
      type: Array,
      default: []
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      cols: [
        { key: 'title', name: 'Title', visible: true, type: 'default' },
        { key: 'amount', name: 'Quantity', visible: true, type: 'default' },
      ],
      sortConfig:{},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
      collection:{},
    }
  },
  computed:{
    tableData(){
      const formattedProductReports = this.reports;
      return formattedProductReports;
    },
    columns(){
            let matches = {};

            matches.title = {
              field: 'title',
              key: 'title',
              title: 'Title',
              fixed: 'left',
              align: 'left',
              width: 240,
              sortBy: this.sortConfig.title || '',
              renderBodyCell: ({row})=>{
                return <span>{row.title}</span>
              }
            }
            matches.amount = {
              field: 'amount',
              key: 'amount',
              title: `Value(${this.currency})`,
              fixed: 'left',
              align: 'right',
              sortBy: this.sortConfig.amount || '',
              renderBodyCell: ({row})=>{
                return <span>{row.amount}</span>
              }
            }

            const preSet = [];
            this.cols.forEach(col=>{
                if (col.visible) {
                    if (col.key in matches) {
                        preSet.push(matches[col.key])
                    }
                }
            })
            return preSet;
        },
    // end of computed
  },
  watch:{
    all_reports:{
      immediate: true,
      handler(newReports){
        this.collection = this.formatData(newReports);
      }
    },
  },
  methods:{
    formatData(data) {
      console.log({formatData:data});

      // Extract unique dates from the data and format them
      const uniqueTitles  = [...new Set(data.map(entry => entry.title))];
      // Initialize the data array with zeros
      const dataValues = new Array(uniqueTitles.length).fill(0);

      // Fill the data array with amounts corresponding to each title
      data.forEach(entry => {
        const index = uniqueTitles.indexOf(entry.title);
        if (index !== -1) {
          dataValues[index] = entry.amount;
        }
      });

      // Create the resulting structure
      return {
        labels: uniqueTitles,
        datasets: [
          {
            label: "Product Report",
            backgroundColor: "#6F1DDF",
            data: dataValues
          }
        ]
      };
    }
    // methods end
  },
}
</script>




<style lang="scss" scoped>
// table styles START
@import '~dashboard/assets/scss/mixins';

.orders-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.orders-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);

    .ve-table-container{
      overflow: visible;
    }
  }


  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
  .status_wrapper select{
    padding-left: 8px;
  }
}

.orders--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}
.loadMore_btn button{
  display: block;
  margin: auto;
}
// table styles END
.margin_section{
  margin-top: var(--space-large);
}
</style>

