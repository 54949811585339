<template>
    <modal :show.sync="isOpen" :on-close="closeModal">
        <div>
            <slot></slot>
        </div>
    </modal>
</template>


<script>
// import Modal from '../../../components/Modal.vue';
import Modal from '../../../components/Modal.vue';

export default {
    components:{
        Modal,
    },
    mixins:{},
    props:{
        title: {
            type: String,
            default : ''
        },
        headerContent:{
            type: String,
            default : ''
        },
        isOpen: {
            type: Boolean,
            default : false
        },
        
    },
    data(){
        return {
            
        }
    },
    computed:{},
    mounted(){},
    methods:{
        closeModal(){
            this.$emit('onClose');
        },
    },
}
</script>

<style>
.modal-popup{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0 15px 2px #e5e3e3;
}
.modal-container{
  padding: 2rem;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
}
.modal_btn{
  display: block;
  margin: auto;
  margin-top: 2rem; 
}
.primary_bg_shade{
    /* --bg_onboard_color: #ffffff, #dcbdfb; */
    --bg_onboard_color: #ffffff, #c795f6;
    background-image: -moz-linear-gradient(to right top, var(--bg_onboard_color));
    background-image: -webkit-linear-gradient(to right top, var(--bg_onboard_color));
    background-image: linear-gradient(to right top, var(--bg_onboard_color));
}
</style>